import React, { useState, useRef } from "react";

import {
	loginWithEmailPassword,
	signInWithFacebook,
	signInWithGoogle,
	registerWithEmailPassword,
} from "../../util/Firebase";
import { useAccountAccess } from "../../util/context/accountContext";

import { ReactComponent as ColoredGoogle } from "../../assets/colored-google-icon.svg";
import { ReactComponent as ColoredFacebook } from "../../assets/colored-facebook-icon.svg";

import "../../css/modal/loginSignup.css";

function LoginSignup({ active, changePanel }) {
	const emailRef = useRef();
	const passwordRef = useRef();
	const [error, setError] = useState();
	const { toggle: toggleAccount } = useAccountAccess();

	const handleGoogle = () => {
		signInWithGoogle()
			.then((res) => {
				toggleAccount();
			})
			.catch((err) => {
				setError(err.data);
			});
	};

	const handleFacebook = () => {
		signInWithFacebook()
			.then((res) => {
				toggleAccount();
			})
			.catch((err) => {
				console.log(err);
				setError(err);
			});
	};

	const handleSubmit = (e) => {
		e.persist();
		e.preventDefault();
		const info = {
			email: emailRef.current.value,
			password: passwordRef.current.value,
		};
		if (active === "login") {
			loginWithEmailPassword(info)
				.then((res) => {
					toggleAccount();
				})
				.catch((err) => {
					console.log("error logging in with email password", err);
					setError(err);
				});
		} else if (active === "signup") {
			registerWithEmailPassword(info)
				.then((res) => {
					toggleAccount();
				})
				.catch((err) => {
					console.log("error registering with email password");
					setError("An unexpected error occurred.");
				});
		}
	};

	return (
		<div className="account-container">
			<h2 className="account-title">
				{active === "signup"
					? "Create an Account"
					: active === "login"
					? "Login to 3 Day"
					: ""}
			</h2>

			<div className="quick-action-container">
				<button onClick={handleGoogle} className="qa-ggle-btn shadow">
					<ColoredGoogle />
				</button>
				<button onClick={handleFacebook} className="qa-fb-btn shadow">
					<ColoredFacebook />
				</button>
			</div>

			<p className="account-alternate-text">- or -</p>

			<form onSubmit={handleSubmit} className="account-form">
				<div className="ls-input-container">
					<input
						type="email"
						id="email"
						name="email"
						ref={emailRef}
						placeholder=" "
						className="account-input-field"
						required
						autoComplete="off"
					/>
					<label htmlFor="email" className="account-input-label">
						Email Address
					</label>
				</div>
				<div className="ls-input-container">
					<input
						type="password"
						id="password"
						name="password"
						ref={passwordRef}
						placeholder=" "
						className="account-input-field"
						required
						autoComplete="off"
					/>
					<label htmlFor="password" className="account-input-label">
						Password
					</label>
				</div>
				<p className="account-err">{error}</p>
				<button className="account-cta-btn">
					{active === "signup" ? "Sign Up" : active === "login" ? "Login" : ""}
				</button>
				<p className="account-msg">
					{active === "login"
						? "Don't have an account?"
						: active === "signup"
						? "Already have an account?"
						: ""}{" "}
					<span
						onClick={() =>
							changePanel(
								active === "signup"
									? "login"
									: active === "login"
									? "signup"
									: ""
							)
						}
					>
						Create one!
					</span>
				</p>
			</form>
		</div>
	);
}

export default LoginSignup;
