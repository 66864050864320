import React, { createContext, useContext } from "react";
import useToggle from "../hooks/useToggle";

const AccountContext = createContext();

export const useAccountAccess = () => {
	const values = useContext(AccountContext);
	return {
		isShowing: values.isShowing,
		toggle: values.toggle,
	};
};

export const AccountProvider = ({ children }) => {
	const [isShowing, toggle] = useToggle();

	return (
		<AccountContext.Provider value={{ isShowing, toggle }}>
			{children}
		</AccountContext.Provider>
	);
};
