import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { Worker } from "@react-pdf-viewer/core";

import PDFViewer from "./PDFViewer.tsx";

import { useAuth } from "../../util/context/authContext";
import { signOutFirebase } from "../../util/Firebase";

import defaultPicture from "../../assets/default-picture.png";

import "../../css/pages/user/dashboard.css";

function Dashboard() {
	const [pdfFile, setPdfFile] = useState(null);
	const [message, setMessage] = useState();
	const { currentUser, idToken } = useAuth();

	const base64ToBytesArray = (base64) => {
		const bytes = atob(base64);
		let length = bytes.length;
		let out = new Uint8Array(length);

		while (length--) {
			out[length] = bytes.charCodeAt(length);
		}
		return [out];
	};

	useEffect(() => {
		const url = `${process.env.REACT_APP_API_URL}/files/three-day-ebook`;
		const options = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `${idToken}`,
			},
		};
		axios
			.post(url, {}, options)
			.then((res) => {
				const array = base64ToBytesArray(res.data);
				const blob = new Blob(array, { type: "application/pdf" });
				const pdfUrl = URL.createObjectURL(blob);
				setPdfFile(pdfUrl);
			})
			.catch((err) => {
				setMessage(err.response.data);
			});
	}, [idToken]);

	return (
		<div className="dashboard-container">
			<div className="dashboard-panel shadow">
				<div className="user-info">
					{currentUser.photoURL && (
						<img src={currentUser.photoURL} alt="profile" />
					)}
					{!currentUser.photoURL && (
						<img src={defaultPicture} alt="default profile" />
					)}
					<p>{currentUser.email}</p>
				</div>
				<nav className="dashboard-nav">
					<ul>
						<li>
							<Link
								to="/#home"
								style={{ textDecoration: "none", color: "inherit" }}
							>
								Home
							</Link>
						</li>
						<li>
							<NavHashLink
								to={"/#about"}
								style={{ textDecoration: "none", color: "inherit" }}
							>
								About
							</NavHashLink>
						</li>
						<li>
							<NavHashLink
								to={"/#testimonials"}
								style={{ textDecoration: "none", color: "inherit" }}
							>
								Testimonials
							</NavHashLink>
						</li>
						<li>Contact</li>
					</ul>
				</nav>
				<div className="logout-container">
					<button onClick={signOutFirebase} className="logout-btn shadow">
						Log Out
					</button>
				</div>
			</div>
			<main>
				<h1>3 Day Potty Training</h1>
				<div className="viewer">
					{!!pdfFile && (
						<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/es5/build/pdf.worker.js">
							<PDFViewer fileUrl={pdfFile} />
						</Worker>
					)}
					{message && <p className="dashboard-msg">{message}</p>}
				</div>
			</main>
		</div>
	);
}

export default Dashboard;
