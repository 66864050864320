import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Home from "./components/home/Home";
import Disclaimer from "./components/Disclaimer";
import Checkout from "./components/checkout/Checkout";
import OrderConfirmation from "./components/checkout/OrderConfirmation";
import PageNotFound from "./components/PageNotFound";
import Dashboard from "./components/user/Dashboard";
import TermsModal from "./components/TermsModal";
import AccountModal from "./components/accountAccess/AccountModal";
import MessageAlert from "./components/MessageAlert";
import PrivateRoute from "./util/PrivateRoute";

import { useMessage } from "./util/context/messageContext";
import { useAccountAccess } from "./util/context/accountContext";
import { useTerms } from "./util/context/termsContext";

import "./css/App.css";

function App() {
	const [showApp, setShowApp] = useState(false);
	const { isShowing: isAccountAccessShowing } = useAccountAccess();
	const { isShowing: isTermsShowing } = useTerms();
	const { isShowing: isMessageShowing, toggleMessage } = useMessage();

	useEffect(() => {
		toggleMessage("Be advised: This is still under construction.");

		// show app when local fonts are ready
		// this prevents the user from seeing the default font while loading
		document.fonts.ready.then(() => {
			setShowApp(true);
		});
	}, []);

	return (
		<>
			{showApp && (
				<div className="App">
					{isAccountAccessShowing && <AccountModal />}
					{isTermsShowing && <TermsModal />}
					{isMessageShowing && <MessageAlert />}

					<Routes>
						<Route
							path="/account"
							element={
								<PrivateRoute>
									<Dashboard />
								</PrivateRoute>
							}
						/>

						<Route
							path="/"
							element={
								<>
									<Header />
									<Home />
								</>
							}
						/>
						{/* <Route path="/checkout" element={<Checkout />} /> */}
						{/* <Route path="/checkout/:step" element={<Checkout />} /> */}
						{/* <Route path="/checkout/:step/:secret" element={<Checkout />} /> */}

						{/* <Route
							path="/confirmation/:secret"
							element={<OrderConfirmation />}
						/> */}

						<Route path="/page-not-found" element={<PageNotFound />} />
						<Route path="*" element={<PageNotFound />} />
						<Route
							path="/pages/disclaimer"
							element={
								<>
									<Header />
									<Disclaimer />{" "}
								</>
							}
						/>
					</Routes>
				</div>
			)}
		</>
	);
}

export default App;
