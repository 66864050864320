import React from "react";
import { useNavigate } from "react-router-dom";

import proudlySeenIn from "../../assets/proudly-seen-in.png";

import "../../css/pages/home/featuredin.css";

function FeaturedIn() {
	const navigate = useNavigate();
	return (
		<section className="featured-in-container">
			<img src={proudlySeenIn} alt="proudly seen in" />
			<div className="bottom">
				<div className="video-wrapper">
					<div className="video-container">
						<iframe
							src="https://6abc.com/video/embed/?pid=707182"
							frameBorder="0"
							allowFullScreen
							scrolling="no"
							className="video"
							allow="autoplay"
							title="ABC News"
							sandbox="allow-forms allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation"
						/>
					</div>
				</div>
				<div className="last-cta">
					<p className="cta-title">Get Started Today!</p>
					<button
						className="cta-btn"
						onClick={() => {
							navigate("/checkout");
						}}
					>
						Buy Now
					</button>
					<p className="price">
						Only $29.99!<span></span>
					</p>
				</div>
			</div>
		</section>
	);
}

export default FeaturedIn;
