import React from "react";
import { useMessage } from "../util/context/messageContext";
import { useAlert } from "../util/hooks/useAlert";

function Message() {
	const { message } = useMessage();
	return <div className="message">{message}</div>;
}

function MessageAlert() {
	const { isShowing, toggle } = useMessage();
	const message = useAlert(<Message />, { isShowing, toggle });
	return <div className="message-alert-container">{message}</div>;
}

export default MessageAlert;
