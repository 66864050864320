import React, { useRef, useState } from "react";

import flower from "../../assets/flower.png";
import { ReactComponent as PersonIcon } from "../../assets/person.svg";
import { ReactComponent as SignIcon } from "../../assets/sign.svg";
import { ReactComponent as MoonIcon } from "../../assets/moon.svg";
import { ReactComponent as AheadIcon } from "../../assets/ahead.svg";
import { ReactComponent as DisabilitiesIcon } from "../../assets/disabilities.svg";

import contents from "../../data/contents.json";

import "../../css/pages/home/item.css";
import "../../css/pages/home/whatsinside.css";

let iconLookup = {
	person: <PersonIcon className="item-icon" />,
	sign: <SignIcon className="item-icon" />,
	moon: <MoonIcon className="item-icon" />,
	ahead: <AheadIcon className="item-icon" />,
	disabilities: <DisabilitiesIcon className="item-icon" />,
};

function WhatsInside() {
	const dragRef = useRef();
	const [isScrolling, setIsScrolling] = useState(false);
	const [clientX, setClientX] = useState(0);
	const [scrollX, setScrollX] = useState(0);

	const onMouseDown = (e) => {
		setIsScrolling(true);
		setClientX(e.clientX);
	};

	const onMouseUp = () => {
		setIsScrolling(false);
		setClientX(0);
	};
	const onMouseMove = (e) => {
		if (isScrolling) {
			// "-+" = drag left to scroll right, drag right to scroll left
			// "+-" = drag right to scroll right, drag left to scroll left
			dragRef.current.scrollLeft = scrollX - e.clientX + clientX;
			setScrollX(() => scrollX - e.clientX + clientX);
			setClientX(() => e.clientX);
		}
	};
	const onMouseLeave = (e) => {
		setIsScrolling(false);
	};

	return (
		<section className="contents-container">
			<div className="contents-top">
				<div className="headline-container">
					<h2 className="headline-title">What's Inside</h2>
					<span className="white top-left" />
					<span className="white square" />
					<span className="white bottom-right" />
				</div>
				<div className="img-container">
					<img src={flower} alt="flower" className="contents-flower shadow" />
				</div>
			</div>

			<div
				ref={dragRef}
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}
				onMouseMove={onMouseMove}
				onMouseLeave={onMouseLeave}
				className="items-viewbox"
			>
				<div className="items-container">
					{contents.map((item) => {
						return (
							<ItemCard
								key={item.id}
								icon={iconLookup[item.icon]}
								title={item.title}
								text={item.text}
								value={item.value}
								id={item.id}
							/>
						);
					})}
				</div>
			</div>
		</section>
	);
}

function ItemCard({ icon, title, text, value }) {
	return (
		<div className="item-card-container shadow">
			{icon}
			<h3 className="item-title">{title}</h3>
			<p className="item-text">{text}</p>
			<p className="item-value">Value: {value}</p>
		</div>
	);
}

export default WhatsInside;
