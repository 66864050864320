import { combineProviders } from "react-combine-providers";

import { AuthProvider } from "./authContext";
import { AccountProvider } from "./accountContext";
import { MessageProvider } from "./messageContext";
import { TermsProvider } from "./termsContext";

const providers = combineProviders();
providers.push(AuthProvider);
providers.push(AccountProvider);
providers.push(MessageProvider);
providers.push(TermsProvider);

export const MasterProvider = providers.master();
