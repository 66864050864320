import { useEffect } from "react";

function useLockBodyScroll(isOpen) {
	useEffect(() => {
		isOpen &&
			(document.body.style.cssText = `position: fixed; overflow-y: scroll`);
		return () => (document.body.style.position = "unset");
	}, [isOpen]);
}

export default useLockBodyScroll;
