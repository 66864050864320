import React, { useState } from "react";

import LoginSignup from "./LoginSignup";
import useModal from "../../util/hooks/useModal";

import { useAccountAccess } from "../../util/context/accountContext";

function ActivePanel() {
	const [panel, setPanel] = useState("login");

	const changePanel = (item) => {
		setPanel(item);
	};

	const currentPanel =
		panel === "signup" ? (
			<LoginSignup active={"signup"} changePanel={changePanel} />
		) : panel === "login" ? (
			<LoginSignup active={"login"} changePanel={changePanel} />
		) : (
			""
		);

	return currentPanel;
}

function AccountModal() {
	const { isShowing, toggle } = useAccountAccess();
	const account = useModal(<ActivePanel />, { isShowing, toggle });
	if (!account) return null;
	return account;
}

export default AccountModal;
