import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyDrlr5KKQxkxg-ChOjCgUP4AoBTd33bS1I",
	authDomain: "potty-training-3-day.firebaseapp.com",
	projectId: "potty-training-3-day",
	storageBucket: "potty-training-3-day.appspot.com",
	messagingSenderId: "104231898641",
	appId: "1:104231898641:web:435466f3c2f409d7f76bb0",
	measurementId: "G-PCJ8V4CGDK",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
