import React from "react";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

import { ReactComponent as Logo } from "../assets/logo.svg";

import "../css/footer.css";

function Footer() {
	return (
		<div className="footer-container">
			<div className="logo-container">
				<h6 className="logo-title">3 Day Potty Training</h6>
				<Logo className="logo" alt="logo" />
			</div>
			<nav className="footer-nav">
				<li>
					<NavHashLink to={"#home"}>Home</NavHashLink>
				</li>
				<li>
					<NavHashLink to={"#about"}>About</NavHashLink>
				</li>
				{/* <li>Contact</li> */}
				<li>
					<NavHashLink to={"#testimonials"}>Success Stories</NavHashLink>
				</li>
				<li>
					<Link
						to="/pages/disclaimer"
						style={{ color: "var(--black)", textDecoration: "none" }}
					>
						Disclaimer
					</Link>
				</li>
				<li className="cta-link">
					<Link
						to={"/checkout"}
						style={{ color: "var(--purple)", textDecoration: "none" }}
					>
						Buy the eBook Now
					</Link>
				</li>
			</nav>
			<p className="footer-disclaimer">
				Lora Jensen's 3 Day Potty Training method is only available at
				3DayPottyTraining.com, and is the internet's most popular and most
				effective training guide, with proven, verifiable potty training success
				stories that you can see and read for yourself on customer's own blogs
				and websites. No other potty training method has this kind of track
				record.
			</p>
			<p className="division">
				3DayPottyTraining.com is a division of WebMommies.com.
			</p>
		</div>
	);
}

export default Footer;
