import * as React from "react";
import { Viewer, RenderPageProps } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import type {
	ToolbarProps,
	ToolbarSlot,
	TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

interface PDFViewerProps {
	fileUrl: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl }) => {
	const renderPage = (props: RenderPageProps) => {
		return (
			<>
				{props.canvasLayer.children}
				<div style={{ userSelect: "none" }}>{props.textLayer.children}</div>
				{props.annotationLayer.children}
			</>
		);
	};

	const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
		...slot,
		Download: () => <></>,
		DownloadMenuItem: () => <></>,
		Open: () => <></>,
		OpenMenuItem: () => <></>,
		// EnterFullScreen: () => <></>,
		// EnterFullScreenMenuItem: () => <></>,
		// SwitchTheme: () => <></>,
		// SwitchThemeMenuItem: () => <></>,
		ShowProperties: () => <></>,
		ShowPropertiesMenuItem: () => <></>,
		ShowSeachPopover: () => <></>,
	});

	const renderToolbar = (
		Toolbar: (props: ToolbarProps) => React.ReactElement
	) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;

	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		renderToolbar,
		sidebarTabs: (defaultTabs) => [
			// remove the attachments tab('defaultTabs[2]')
			defaultTabs[0], // bookmarks tab
			defaultTabs[1], // thumbnails tab
		],
	});
	const { renderDefaultToolbar } =
		defaultLayoutPluginInstance.toolbarPluginInstance;

	return (
		<Viewer
			fileUrl={fileUrl}
			plugins={[defaultLayoutPluginInstance]}
			theme="dark"
			defaultScale={1.5}
			renderPage={renderPage}
		/>
	);
};

export default PDFViewer;
