import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/authContext";

function PrivateRoute({ children }) {
	const navigate = useNavigate();
	const { isAuthenticated } = useAuth();
	return isAuthenticated ? children : navigate("../", { replace: true });
}

export default PrivateRoute;
