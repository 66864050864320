import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
	loginWithEmailPassword,
	registerWithEmailPassword,
	signInWithFacebook,
	signInWithGoogle,
} from "../../util/Firebase";

import { ReactComponent as ColoredGoogle } from "../../assets/colored-google-icon.svg";
import { ReactComponent as ColoredFacebook } from "../../assets/colored-facebook-icon.svg";

import "../../css/pages/checkout/accountinfo.css";

function AccountInfo() {
	const emailRef = useRef();
	const passwordRef = useRef();
	const [error, setError] = useState(null);
	const [form, setForm] = useState("signup"); // available forms are 'signup' and 'login'
	const navigate = useNavigate();

	const handleGoogle = () => {
		signInWithGoogle()
			.then((res) => {
				console.log("google sign in successful");
				navigate("/checkout/billing");
			})
			.catch((err) => {
				setError("Google sign in failed");
			});
	};

	const handleFacebook = () => {
		signInWithFacebook()
			.then((res) => {
				navigate("/checkout/billing");
			})
			.catch((err) => {
				setError("Facebook sign in failed");
			});
	};

	const handleSubmit = (e) => {
		e.persist();
		e.preventDefault();
		const info = {
			email: emailRef.current.value,
			password: passwordRef.current.value,
		};
		if (!!info.email === false || !!info.password === false) {
			setError("Missing required fields");
		} else {
			if (form === "signup") {
				registerWithEmailPassword(info)
					.then((res) => {
						navigate("/checkout/billing");
					})
					.catch((err) => {
						setError(err);
					});
			}

			if (form === "login") {
				loginWithEmailPassword(info)
					.then((res) => {
						navigate("/checkout/billing");
					})
					.catch((err) => {
						setError(err);
					});
			}
		}
	};

	return (
		<div className="checkout-account">
			<div className="checkout-account-container">
				<div className="ch-acc-title">
					<h1>Account Info</h1>
					<h6>Note: An account is needed to view and/or print the eBook</h6>
				</div>
				<div className="quick-account-container">
					<button className="custom-google-btn shadow" onClick={handleGoogle}>
						<ColoredGoogle />
					</button>
					<button
						className="custom-facebook-btn shadow"
						onClick={handleFacebook}
					>
						<ColoredFacebook />
					</button>
				</div>
				<form className="account-info" onSubmit={handleSubmit}>
					<h6>
						{form === "signup"
							? "Create an Account"
							: form === "login"
							? "Login to Account"
							: ""}
					</h6>
					<div className="account-input-container">
						<input
							type="email"
							id="email"
							name="email"
							ref={emailRef}
							placeholder=" "
							className="account-input-field"
							required
							autoComplete="off"
						/>
						<label htmlFor="email" className="account-input-label">
							Email Address
						</label>
					</div>
					<div className="account-input-container">
						<input
							type="password"
							id="password"
							name="password"
							ref={passwordRef}
							placeholder=" "
							className="account-input-field"
							required
							autoComplete="off"
						/>
						<label htmlFor="password" className="account-input-label">
							Password
						</label>
					</div>
					{error && <p className="err">{error}</p>}
					<p className="ch-acc-msg">
						{form === "signup"
							? "Already have an account? Login "
							: form === "login"
							? "Don't have an account? Create one "
							: ""}
						<span
							onClick={
								form === "signup"
									? () => {
											setError("");
											setForm("login");
									  }
									: form === "login"
									? () => {
											setError("");
											setForm("signup");
									  }
									: ""
							}
						>
							here
						</span>
					</p>
					<div className="checkout-btns">
						<button className="accent" onClick={() => navigate("/")}>
							Cancel
						</button>

						<button type="submit" className="cta">
							Continue to Billing
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default AccountInfo;
