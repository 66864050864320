import React, { useState, createContext, useContext } from "react";
import useToggle from "../hooks/useToggle";

const MessageContext = createContext();

export const useMessage = () => {
	const values = useContext(MessageContext);

	const toggleMessage = (newMessage) => {
		values.setMessage(newMessage);
		values.toggle();
	};
	return {
		message: values.message,
		setMessage: values.setMessage,
		isShowing: values.isShowing,
		toggle: values.toggle,
		toggleMessage: toggleMessage,
	};
};

export const MessageProvider = ({ children }) => {
	const [message, setMessage] = useState(null);
	const [isShowing, toggle] = useToggle();

	return (
		<MessageContext.Provider value={{ message, setMessage, isShowing, toggle }}>
			{children}
		</MessageContext.Provider>
	);
};
