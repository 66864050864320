import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MasterProvider } from "./util/context/MasterProvider";

import App from "./App";

import "./config/firebase-config";
import "./css/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<BrowserRouter>
		<MasterProvider>
			<App />
		</MasterProvider>
	</BrowserRouter>
	// </React.StrictMode>
);
