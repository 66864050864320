import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./context/authContext";
import { useMessage } from "./context/messageContext";

function PrivateLink({ children, destination, message }) {
	const { toggleMessage } = useMessage();
	const { isAuthenticated } = useAuth();

	const isAllowed = () => {
		if (!isAuthenticated) {
			toggleMessage(message);
		}
	};
	return (
		<Link to={isAuthenticated ? destination : ""} onClick={isAllowed}>
			{children}
		</Link>
	);
}

export default PrivateLink;
