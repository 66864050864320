import React from "react";

function PageNotFound() {
	return (
		<div className="404-container">
			<h1>Error 404: Page Not Found</h1>
		</div>
	);
}

export default PageNotFound;
