import {
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	signInWithPopup,
	sendEmailVerification,
	signOut,
	FacebookAuthProvider,
	createUserWithEmailAndPassword,
} from "firebase/auth";
import axios from "axios";
import { auth } from "../config/firebase-config";

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");

export function signInWithGoogle() {
	return new Promise(function (resolve, reject) {
		signInWithPopup(auth, googleProvider)
			.then((res) => {
				// const credential = GoogleAuthProvider.credentialFromResult(res);
				// const googleIDToken = credential.idToken; // returns jwt

				// api create user
				const user = {
					uid: res.user.uid,
					displayName: res.user.displayName,
					email: res.user.email,
					account: "google",
				};

				createUser("google", user)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						if (err.response.status === 409) {
							resolve(err.response);
						} else {
							reject(err.response);
						}
					});
			})
			.catch((err) => {
				reject(err.code);
			});
	});
}

const facebookProvider = new FacebookAuthProvider();
facebookProvider.addScope("email");

export function signInWithFacebook() {
	return new Promise(function (resolve, reject) {
		signInWithPopup(auth, facebookProvider)
			.then((res) => {
				// This gives you a Facebook Access Token. You can use it to access the Facebook API
				// const credential = FacebookAuthProvider.credentialFromResult(res);
				// const accessToken = credential.accessToken;

				// api create user
				const user = {
					uid: res.user.uid,
					displayName: res.user.name,
					email: res.user.email,
					account: "facebook",
				};
				createUser("facebook", user)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						if (err.response.status === 409) {
							resolve(err.response);
						} else {
							reject(err.response);
						}
					});
			})
			.catch((err) => {
				console.log("error signing in with facebook", err.code);
				reject(err.code);
			});
	});
}

export function registerWithEmailPassword(userInfo) {
	return new Promise(function (resolve, reject) {
		// add user to firebase auth
		createUserWithEmailAndPassword(auth, userInfo.email, userInfo.password)
			.then((res) => {
				const firestoreUser = {
					uid: res.user.uid,
					displayName: res.user.displayName,
					email: res.user.email,
					account: "basic",
				};
				// add user to firestore from api
				createUser("authenticate", firestoreUser)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err.response);
					});
			})
			.catch((err) => {
				reject(err.code);
			});
	});
}

export function loginWithEmailPassword(userInfo) {
	return new Promise(function (resolve, reject) {
		signInWithEmailAndPassword(auth, userInfo.email, userInfo.password)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err.code);
			});
	});
}

export async function sendVerificationEmail() {
	const response = await sendEmailVerification(auth.currentUser);
	return response;
}

export function signOutFirebase() {
	signOut(auth)
		.then((res) => {
			console.log("signed out");
		})
		.catch((err) => {
			console.log("error signing out", err);
		});
}

export async function verifyToken(token) {
	const url = `${process.env.REACT_APP_API_URL}/authenticate/token`;
	const body = {};
	const config = {
		headers: { Authorization: `${token}` },
	};
	axios
		.post(url, body, config)
		.then((res) => {
			console.log("token verified", res);
		})
		.catch((err) => {
			console.log("error verifying token", err);
		});
}

export function isLoggedIn() {
	const user = auth.currentUser;
	if (user) {
		return true;
	} else {
		return false;
	}
}

// creates a new user on the backend
// destination: google, facebook, or email api route
// userInfo: {uid, displayName, email, account}
export function createUser(destination, userInfo) {
	const promise = new Promise(function (resolve, reject) {
		const url = `${process.env.REACT_APP_API_URL}/${destination}/register`;
		const headers = {
			"Content-Type": "application/json",
		};
		axios
			.post(url, userInfo, { header: { headers } })
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
	return promise;
}
