import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import PrivateLink from "../util/PrivateLink";

import { ReactComponent as Logo } from "../assets/logo.svg";
import "../css/header.css";

import useClickOutside from "../util/hooks/useClickOutside";
import { useAccountAccess } from "../util/context/accountContext";

function Header() {
	const { toggle: toggleAccount } = useAccountAccess();
	const wrapperRef = useRef();

	const toggleEffects = (nav, lines) => {
		nav.classList.toggle("nav-active");
		Array.prototype.forEach.call(lines, (line) =>
			line.classList.toggle("toggle")
		);
	};
	const selectDOMItems = () => {
		const nav = document.querySelector(".main-nav");
		const lines = document.querySelectorAll("[class^='line']");
		return { nav, lines };
	};

	// only toggleEffects if classList contains name of 'nav-active'
	const resetNav = () => {
		const { nav, lines } = selectDOMItems();
		if (nav.classList.contains("nav-active")) {
			toggleEffects(nav, lines);
		}
	};

	useClickOutside(wrapperRef, resetNav);

	const toggleNav = () => {
		const { nav, lines } = selectDOMItems();
		toggleEffects(nav, lines);
	};

	const handleLinkClick = () => {
		if (document.querySelector(".main-nav").classList.contains("nav-active")) {
			toggleNav();
		}
	};

	const handleLogin = () => {
		handleLinkClick();
		toggleAccount();
	};

	return (
		<div className="header-container">
			<div className="header" ref={wrapperRef}>
				<div className="burger-nav" onClick={toggleNav}>
					<div className="line1" />
					<div className="line2" />
					<div className="line3" />
				</div>
				<div className="logo-container">
					<Logo className="logo" />
					<p className="logo-title">3 Day Potty Training</p>
					<p className="logo-short">3 Day</p>
				</div>
				<nav className="main-nav">
					<li onClick={handleLinkClick}>
						<NavHashLink to={"/#home"}>Home</NavHashLink>
					</li>
					<li onClick={handleLinkClick}>
						<NavHashLink to={"/#about"}>About</NavHashLink>
					</li>
					<li onClick={handleLinkClick}>
						<NavHashLink to={"/#testimonials"}>Testimonials</NavHashLink>
					</li>
					<li onClick={handleLinkClick}>
						<Link to={"/checkout"} className="cta-link">
							Buy the eBook Now
						</Link>
					</li>
					{/* <li onClick={handleLogin}>Log in</li> */}
					{/* <li onClick={handleLinkClick}>
						<PrivateLink
							destination={"/account"}
							message={"Must be logged in to continue"}
						>
							Account
						</PrivateLink>
					</li> */}
				</nav>
			</div>
		</div>
	);
}

export default Header;
