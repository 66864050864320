import React, { createContext, useContext } from "react";
import useToggle from "../hooks/useToggle";

const TermsContext = createContext();

export const useTerms = () => {
	const values = useContext(TermsContext);

	return {
		isShowing: values.isShowing,
		toggle: values.toggle,
	};
};
export const TermsProvider = ({ children }) => {
	const [isShowing, toggle] = useToggle();

	return (
		<TermsContext.Provider value={{ isShowing, toggle }}>
			{children}
		</TermsContext.Provider>
	);
};
