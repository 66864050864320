import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LoopArrow } from "../../assets/loop-arrow.svg";
import eBook from "../../assets/eBook.jpg";

import "../../css/pages/home/ctabanner.css";

function CTABanner() {
	const navigate = useNavigate();
	return (
		<section id="sec5" className="cta-banner">
			<div className="cta-banner-container">
				<div className="col left">
					<p className="cta-title">Original eBook</p>
					<h6>Since 2006</h6>
					<LoopArrow className="loop-arrow" />
				</div>
				<div className="stacked-ebook">
					<img src={eBook} alt="cover for eBook" className="ebook-cover" />
					<img
						src={eBook}
						alt="cover for eBook"
						className="ebook-cover tilted"
					/>
				</div>
				<div className="col right">
					<p className="cta-title">Get Yours Today!</p>
					<button
						className="cta-btn"
						onClick={() => {
							navigate("/checkout");
						}}
					>
						Buy Now
					</button>
					<p className="price">Only $29.99</p>
				</div>
			</div>
			<div className="cta-banner-blur">
				<div className="cta-banner-bg" />
			</div>
		</section>
	);
}

export default CTABanner;
