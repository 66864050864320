import React from "react";
import { useNavigate } from "react-router-dom";

import heroImg from "../../assets/hero.jpg";

import "../../css/pages/home/hero.css";

function Hero() {
	let navigate = useNavigate();
	return (
		<div className="hero-container" id="home">
			<div className="left-banner" />
			<div className="lp-left-col">
				<div className="main-content">
					<h1>3 Day Potty Training</h1>
					<h6>Providing Help Since 2006</h6>

					<p className="description">
						3 Day Potty Training is a focused program in which you spend 3
						consecutive days working to potty train your child.
					</p>

					<button
						className="cta-btn"
						onClick={() => {
							navigate("/checkout");
						}}
					>
						Buy Now
					</button>
				</div>
			</div>
			<div className="lp-right-col">
				<img
					fetchpriority="high"
					src={heroImg}
					alt="child sitting on toilet"
					className="hero-img"
					loading="lazy"
				/>
			</div>
		</div>
	);
}

export default Hero;
