import { useRef } from "react";
import ReactDom from "react-dom";

import { ReactComponent as Close } from "../../assets/close.svg";
import "../../css/modal/alert.css";
import useClickOutside from "./useClickOutside";

export function useAlert(content, { isShowing, toggle }) {
	const alertRef = useRef();

	useClickOutside(alertRef, () => toggle());

	return isShowing
		? ReactDom.createPortal(
				<div className="alert-background">
					<div className="alert-container" ref={alertRef}>
						{content}
						<button className="alert-btn" onClick={() => toggle()}>
							<Close />
						</button>
					</div>
				</div>,
				document.getElementById("alert-root")
		  )
		: null;
}
