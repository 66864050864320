import React from "react";

import useModal from "../util/hooks/useModal";
import { useTerms } from "../util/context/termsContext";

import "../css/modal/terms.css";

function TermsOfService() {
	return (
		<div className="terms">
			<h2>Terms of Service</h2>
			<p>
				Your credit statement will say <span>WEBMOMMIES</span>
				<br />
				<br />
				You acknowledge that you are authorized to use the inteded credit or
				debit card to make the purchase. If the credit or debit card does not
				belong to you or does not bear your name, you willingly agree to
				personally accept all responsibility for the purchase.
				<br />
				<br />
				Your IP address and corresponding geographic location have been
				recorded. Attempted fraud will be reported.
				<br />
				<br />
				My refund policy requires that you use my product. I will gladly honor
				your refund request if I believe you have honestly tried to use the 3
				Day Potty Training method but have failed to change your child’s
				behavior while doing so.
				<br />
				<br />
				Simply telling me, “I tried but it didn’t work” will not get you a
				refund. The same agoes for saying, “It’s not what I expected.” If this
				simple refund policy does not seem fair to you, or if you are not
				willing to abide by it, please don’t buy my ebook.
				<br />
				<br />
				I stand by my product. I will give you a refund if it doesn’t work. But
				I must believe and know that you have genuinely tried to use the 3 Day
				Potty Training method but have failed to change your child’s behavior
				while doing so before I will give you a refund. Once your refund is
				processed or if your payment is reversed you will not be permitted to
				view the ebook.
				<br />
				<br />
				You are purchasing an electronic book (“eBook”). eBooks are downloadable
				computer files - not physical books. Physical books can be return for a
				refund; downloadble computer files cannot.
				<br />
				<br />
				By purchasing my ebook or using my mentoring services, you agree to
				these terms.
			</p>
		</div>
	);
}

function TermsModal() {
	const { isShowing, toggle } = useTerms();
	const terms = useModal(<TermsOfService />, { isShowing, toggle });
	if (!terms) return null;
	return terms;
}

export default TermsModal;
