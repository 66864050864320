import React from "react";

import { ReactComponent as QueenIcon } from "../../assets/queen.svg";
import loraPortrait from "../../assets/lora-jensen-portrait.png";

import "../../css/pages/home/aboutauthor.css";

function AboutAuthor() {
	return (
		<section className="about-author" id="about">
			<div className="meet shadow">
				<h2 className="headline">Meet the Author</h2>
				<div className="name-container">
					<h6 className="name">Lora Jensen</h6>
					<QueenIcon className="queen" />
				</div>
				<img
					src={loraPortrait}
					alt="portrait of lora jensen"
					className="portrait hidden"
				/>
				<ul className="about-ls">
					<li>I'm a mom of six. Five boys and one girl - she's the baby!</li>
					<li>
						I am still madly in love with my husband after 30 years of marriage
					</li>
					<li>I'm a grandma to five - three girls and two boys</li>
					<li>I have a hard time saying "no"</li>
				</ul>
				{/* <br /> */}
				<p className="about-desc">
					I am the self-proclaimed "Potty Training Queen". I'm sure I sound a
					little crazy when I say that but honestly, after potty training my own
					five boys and one daughter, three of my 6 foster kids, and helping
					over 3000 parents potty train their children in three days or less, I
					feel like I know pretty much everything there is to know about potty
					training.
				</p>
			</div>
			<div className="abstract-profile shadow">
				<div className="vertical-left">
					<img
						src={loraPortrait}
						alt="portrait of lora jensen"
						className="portrait abs"
					/>
					<span className="shape light top-right m" />
					<span className="shape light bottom-left" />
					<span className="shape white top-left" />
				</div>
				<div className="vertical-right">
					<span className="shape green top-right" />
					<span className="shape dark top-left" />
					<span className="shape light bottom-right" />
				</div>
			</div>
		</section>
	);
}

export default AboutAuthor;
