import React from "react";
import Hero from "./Hero";
import AboutAuthor from "./AboutAuthor";
import Story from "./Story";
import WhatsInside from "./WhatsInside";
import CTABanner from "./CTABanner";
import Testimonial from "./Testimonial";
import FeaturedIn from "./FeaturedIn";
import Footer from "../Footer";

function Home() {
	return (
		<>
			<Hero />
			<AboutAuthor />
			<Story />
			<WhatsInside />
			<CTABanner />
			<Testimonial />
			<FeaturedIn />
			<Footer />
		</>
	);
}

export default Home;
