import React from "react";
import { useNavigate } from "react-router-dom";

import eBook from "../../assets/eBook.jpg";

import "../../css/pages/home/story.css";

function Story() {
	const navigate = useNavigate();
	return (
		<section id="story" className="story-container">
			<div className="cta-pane">
				<div className="cta-pane-wrapper">
					<img src={eBook} alt="cover for eBook" className="ebook-cover" />
					<button
						className="cta-btn"
						onClick={() => {
							navigate("/checkout");
						}}
					>
						Buy Now
					</button>
					<p className="price">Only $29.99!</p>
				</div>
				<div className="cta-bg-blur">
					<div className="abst-bg" />
				</div>
			</div>
			<div className="story">
				<div className="headline-container">
					<h2 className="headline-title">My Story</h2>
					<span className="white top-left" />
					<span className="white square" />
					<span className="white bottom-right" />
				</div>
				<p className="story-text">
					My story begins in 1994, my first born is 14 months old and I recently
					gave birth to number two. Here I was with two babies, both in diapers.
					I was a stay at home mom and my husband was in school full-time with
					only a part-time income. It was very difficult to buy diapers for two
					and wasn’t looking forward to the cost of pull-ups either.
					<br />
					<br />
					One night while I was laying in bed with my husband, I was telling him
					how I couldn’t believe that my nephew was still in a diaper and he was
					almost five years old. I was also telling him about all the ways I
					thought my cousins and friends were making mistakes with potty
					training. His response to me was, “Lora, you don’t have to do it their
					way. Come up with your own plan that you think will work.” So that is
					what I did!
					<br />
					<br />
					So I began watching my cousins and friends more closely while making
					mental notes of things I was NOT going to do. I even visited my mom
					and grandmother to get advice on potty training. With all these mental
					notes of what to do and what not to do, I started potty training my 22
					month old son; and, guess what? Three days later, he was fully potty
					trained!!
					<br />
					<br />I then used this method on boy number two then number three.
					Friends and family then started asking me how I could do it so fast. I
					explained my method and they too had the same results.
				</p>
			</div>
		</section>
	);
}

export default Story;
