import React from "react";

import cloud from "../../assets/cloud.svg";
import comment from "../../assets/comment.svg";
import arrow from "../../assets/arrow.svg";
import testimonialBlob from "../../assets/testimonial-blob.png";

import testimonials from "../../data/testimonials.json";

import "../../css/pages/home/testimonial.css";

function Testimonial() {
	return (
		<section className="testimonials-wrapper" id="testimonials">
			<div className="testimonials-container">
				<div className="left">
					<img src={cloud} alt="" className="cloud" id="cloud-tp-l" />
					<img src={cloud} alt="" className="cloud" id="cloud-tp-r" />
					<h2>Don't Believe Me? See What Other Parents Are Saying:</h2>
					<img src={comment} alt="comment icon" className="comment-icon" />
					<img src={cloud} alt="" className="cloud" id="cloud-btm-l" />
					<img src={cloud} alt="" className="cloud" id="cloud-btm-r" />
				</div>
				<img src={arrow} alt="arrow" className="arrow" />
				<div className="right">
					{testimonials.map((item) => {
						return (
							<div className="testimonial" key={item.id}>
								<img
									src={testimonialBlob}
									alt="testimony blob"
									className="testimonial-blob"
								/>
								<TestimonialCard data={item} />
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
}

function TestimonialCard({ data }) {
	return (
		<div className="testimonial-card-container">
			{Object.keys(data).map((item, idx) => {
				// return every field in data object except 'id'
				return item !== "id" && item ? (
					<p className={`testimonial-${item}`} key={idx}>
						{data[item]}
					</p>
				) : null;
			})}
			<p className="testimonial-disclaimer">* Your experience may vary</p>
			{/* Read More / Collapse */}
			<input className="testimonial-expand" type="checkbox" />
		</div>
	);
}

export default Testimonial;
