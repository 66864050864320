import React from "react";

import "../css/pages/disclaimer.css";

function Disclaimer() {
	return (
		<div className="disclaimer-container shadow">
			<div className="disclaimer">
				<h2>Disclaimers</h2>
				<ul className="disclaimer-list">
					<li className="disclaimer-item">
						I am not a doctor; I am just one parent helping another. You know
						your child better than I do. If you have any concerns, consult your
						doctor.
					</li>
					<li className="disclaimer-item">
						I love being pleasantly surprised. Don't you? So let's not hold to
						the expectation that{" "}
						<span style={{ "text-decoration": "underline" }}>
							3 Day Potty Training
						</span>{" "}
						is some kind of miracle program that guarantees that *any* child
						will be potty trained in 3 days. That way, if it happens for you
						(and there is a good chance it will)... HAPPY DAY!
					</li>
					<li className="disclaimer-item">
						Neither I nor those who work with me accept any responsibility for
						the misuse of this product.
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Disclaimer;
