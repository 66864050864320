import { useRef } from "react";
import ReactDom from "react-dom";
import useLockBodyScroll from "./useLockBodyScroll";

import { ReactComponent as Close } from "../../assets/close.svg";
import "../../css/modal/modal.css";

export default function useModal(content, { isShowing, toggle }) {
	const modalRef = useRef();

	useLockBodyScroll(isShowing);

	return isShowing
		? ReactDom.createPortal(
				<div className="modal">
					<div className="modal-container" ref={modalRef}>
						{content}
						<button className="close-btn" onClick={toggle}>
							<Close />
						</button>
					</div>
				</div>,
				document.getElementById("modal-root")
		  )
		: null;
}
