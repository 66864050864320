import React, {
	useState,
	createContext,
	useContext,
	useEffect,
	useMemo,
} from "react";
import { auth } from "../../config/firebase-config";

const AuthContext = createContext();

export const useAuth = () => {
	const auth = useContext(AuthContext);
	return {
		currentUser: auth.currentUser,
		isAuthenticated: !!auth.currentUser,
		idToken: auth.idToken,
	};
};

export const AuthProvider = ({ children }) => {
	const [idToken, setIdToken] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);

	// TODO: reconfigure user to not include sensitive information

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			console.log("auth", user);
			if (user) {
				setCurrentUser(user);
				user
					.getIdToken()
					.then((res) => {
						setIdToken(res);
					})
					.catch((err) => {
						console.log("error getting token", err);
					});
			} else {
				setCurrentUser(null);
				setIdToken(null);
			}
		});
		return () => unsubscribe();
	}, []);

	return (
		// <AuthContext.Provider value={[idToken, currentUser]}>
		<AuthContext.Provider value={{ currentUser, idToken }}>
			{children}
		</AuthContext.Provider>
	);
};
